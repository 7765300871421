import React, { Component } from 'react'
import Motion from '../components/Motion'
import Layout from '../layouts/Layout'
import '../styles/scss/motion.scss'

export default class motion extends Component {
    render() {
        return (
            <Layout>
                <Motion /> 
            </Layout>
        )
    }
}
